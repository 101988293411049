import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { Spin } from 'antd';
import Select from 'react-select';

const GET_OPPORTUNITY_TYPES = gql`
  query OpportunityTypeByTeam {
    opportunityTypeByTeam {
      opp_type
    }
  }
`;
const ItemsPerPage = 10;
const oppsData = gql`
query GetTeamSettings($filter: OppFilterInput) {
  Opps(filter: $filter) {
    opp_key
    sisu_client_updated_ts
    opp_updated_ts
    opp_stage
    opp_agreement_signed_date
    fub_deal_created_ts
    opp_type
    opp_appt_date
    fub_deal_stage_name
    opp_appt_met_date
    fub_person_id
    sisu_client_created_ts
    opp_created_ts
    fub_deal_id
    opp_appt_disposition
    teamFubDealId
    team
    fub_deal_entered_stage_ts
    sisu_client_id
    opp_assigned_osa
    opp_isa
    opp_notes
    opp_address
    opp_agreement_expiration_date
    appt_set_entry_id
    fub_appt_start_time
    fub_original_appt_start_time
    disp_text_wait_timestamp
    appt_set_lead_type
    appt_set_platform
    disp_text_original_wait_timestamp
    opp_address2
    opp_city
    opp_postal_code
    opp_last_name
    opp_state
    previous_opp_stage
    CreateEntryId
    pipeline_entry_id
    opp_forecasted_close_date
    opp_under_contract_date
    appt_form_id
    form_id_entry_id
    opp_settlement_date
    CreateFormId
    appt_outcome
    external_system_key_buyer
    otc_property_id
    external_system_key_seller
    FormId_EntryIds
    opp_price
    opp_commission_percent
    fub_appt_id
    opp_custom_fields
    custom_fields
   
  },
  GetFieldsforTeam{
    field_id
    field_name
    field_type
    input_type
    settings
    field_sub_type
    opportunity_field_id
  }
}`

export default function OpportunitysList() {

  const searchTermref = useRef("");
  const [dashboardcounts, setdashboardcounts] = useState({
    oppcount: 0,
    totalOppPrice: 0,
    totalCgi: 0,
  });
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const selectedOptionsref = useRef([]);
  const [rowData, setRowData] = useState([]);
  const [isTableDataProcessing, setIsTableDataProcessing] = useState(true);
  // const [gridApi, setGridApi] = useState(null);
  // useref for gridRef
  const gridRef = useRef(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);
  const dateFormatter = (params) => {
    if (!params.value) return '';
    const dateStr = params.value.toString();

    let year, month, day;

    if (dateStr.includes('-')) {
      // Handle YYYY-MM-DD format
      [year, month, day] = dateStr.split('-');
    } else {
      // Handle YYYYMMDD format
      year = dateStr.substring(0, 4);
      month = dateStr.substring(4, 6);
      day = dateStr.substring(6, 8);
    }

    return `${day}/${month}/${year}`;
  };

  const exportToCsv = () => {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsCsv();
    }
  };

  // make columnDefs a usestate
  const [columnDefs, setColumnDefs] = useState([]);
  
  // ----- Default state of ag grid------
  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
    floatingFilter: true,
    cellClass: 'ag-cellz',
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
  };

  // ----- Design each column type------
  const columnTypes = useMemo(() => {
    return {
      agent: {
        minWidth: 240,
        cellStyle: { fontWeight: '500' },
      },
      action: {
        minWidth: 100,
      },
    };
  }, []);

  // ----- to edit the tool and sidebar------
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        // toolPanelParams: {
        //   // suppressRowGroups: false,
        //   suppressValues: true,
        //   // suppressPivots: true,
        //   // suppressPivotMode: true,
        // },
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };


  const { loading: loadingTypes, error: errorTypes, data: dataTypes } = useQuery(GET_OPPORTUNITY_TYPES, {
    notifyOnNetworkStatusChange: true,
  });

  const onGridReady = (params) => {
    // setGridApi(params.api);
    // setGridColumnApi(params.columnApi);

    // Refresh the data when the filter changes
    // params.api.addEventListener("filterChanged", handleRefetchClick);
  };


  function CustomGroupCellRenderer(params) {
    return params.value;
  }

  const onFilterChanged = async (event) => {
    console.log('Filter Changed', event);
    // return
    let totalPrice = 0;
    let totalCgi = 0;
    let count = 0;
    gridRef.current?.api.forEachNodeAfterFilter((node) => {
      // console.log(node.data);
      if (!node.data) return;
      count += 1;
      if (typeof node.data.opp_price === 'number' && !isNaN(node.data.opp_price)) {
         totalPrice = parseFloat((node.data.opp_price + totalPrice).toFixed(2));
         if (typeof node.data.opp_commission_percent === 'number' && !isNaN(node.data.opp_commission_percent)) {
          const totalPriceWithCommission = node.data.opp_price * (node.data.opp_commission_percent);
          totalCgi = parseFloat((totalPriceWithCommission + totalCgi).toFixed(2));
      }
    }

    })
    setdashboardcounts({
      oppcount: count,
      totalOppPrice: totalPrice,
      totalCgi: totalCgi,
    });
  };

  const { loading, error, data, refetch } = useQuery(oppsData, {
    variables: {
      filter: {
        // opp_stage: getValueForm,
        opp_type: [],
        // opp_custom_fields: selectedCommission

      }
    },
    // notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    // append other columns to columnDefs from oppsData.GetFieldsforTeam
    console.log("Data Change", data);
    if (data) {
      let columnDefs = [
        {
          headerName: "",
          type: "action",
          // headerCheckboxSelection: true,
          // checkboxSelection:true,
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            innerRenderer: (params) => (
              <i
                className="iconify pt-1 fs-5 pointer"
                data-icon="fluent:eye-24-regular"
              ></i>
            ),
          },
        },
        {
          headerName: "Agent Name", field: "opp_assigned_osa", type: 'agent', enableRowGroup: true, filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
          rowGroup: true
        },
        {
          headerName: "FUB Lead ID", field: "fub_person_id", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
        },
        // filter: false because we already have opp type filter as external filter
        {
          headerName: "Opportunity Type", field: "opp_type", filter: false
        },
        {
          headerName: "FUB Deal ID", field: "fub_deal_id", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
    
          },
        },
        {
          headerName: "Title", field: "opp_last_name", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
            debounceMs: 500,
          },
        },
        { headerName: "Stage", field: "opp_stage" },
        {
          headerName: "Agreement Signed Date",
          field: "opp_agreement_signed_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: (filterDate, cellValue) => {
              if (!cellValue) return -1;
              const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
              return cellDate.getTime() - filterDate.getTime();
            },
            browserDatePicker: true,
          },
          // valueFormatter: (params) => formatDate(params.value),
        },
        {
          headerName: "Appt Date",
          field: "opp_appt_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: (filterDate, cellValue) => {
              if (!cellValue) return -1;
              const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
              return cellDate.getTime() - filterDate.getTime();
            },
            browserDatePicker: true,
          },
          headerTooltip: "Appointment Date of opportunity or lead"
          // valueFormatter: (params) => formatDate(params.value),
        },
        {
          headerName: "Appt Met Date",
          field: "opp_appt_met_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: (filterDate, cellValue) => {
              if (!cellValue) return -1;
              const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
              return cellDate.getTime() - filterDate.getTime();
            },
            browserDatePicker: true,
          },
          // valueFormatter: (params) => formatDate(params.value),
        },
        {
          headerName: "Price", field: "opp_price", filter: 'agNumberColumnFilter',
          filterParams: {
            applyButton: true,
            clearButton: true,
            inRangeInclusive: true,
          }, cellStyle: params => params.value > 80 ? { color: 'green' } : { color: 'black' },
          aggFunc: "sum"
        },
        {
          headerName: "Sisu Client ID", field: "sisu_client_id", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
        },
        {
          headerName: "Agreement Expiration Date",
          field: "opp_agreement_expiration_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: (filterDate, cellValue) => {
              if (!cellValue) return -1;
              const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
              return cellDate.getTime() - filterDate.getTime();
            },
            browserDatePicker: true,
          },
          // valueFormatter: (params) => formatDate(params.value),
        },
        {
          headerName: "Commission Percent", field: "opp_commission_percent", filter: 'agNumberColumnFilter',
          filterParams: {
            applyButton: true,
            clearButton: true,
            inRangeInclusive: true,
          }
        },
        { headerName: "Commission Point", field: "custom_commission_point" },
        {
          headerName: "Postal Code", field: "opp_postal_code", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
        },
        {
          headerName: "Last Name", field: "opp_last_name", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
        },
        { headerName: "State", field: "opp_state" },
        {
          headerName: "Forecasted Close Date",
          field: "opp_forecasted_close_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: (filterDate, cellValue) => {
              if (!cellValue) return -1;
              const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
              return cellDate.getTime() - filterDate.getTime();
            },
            browserDatePicker: true,
          },
          // valueFormatter: (params) => formatDate(params.value),
        },
        {
          headerName: "Under Contract Date",
          field: "opp_under_contract_date",
          valueFormatter: dateFormatter,
          filterParams: {
            comparator: (filterDate, cellValue) => {
              if (!cellValue) return -1;
              const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
              return cellDate.getTime() - filterDate.getTime();
            },
            browserDatePicker: true,
          },
          // valueFormatter: (params) => formatDate(params.value),
        },
        {
          headerName: "Settlement Date",
          field: "opp_settlement_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: (filterDate, cellValue) => {
              if (!cellValue) return -1;
              const cellDate = new Date(cellValue.substring(0, 4), cellValue.substring(4, 6) - 1, cellValue.substring(6, 8));
              return cellDate.getTime() - filterDate.getTime();
            },
            browserDatePicker: true,
          },
          // valueFormatter: (params) => formatDate(params.value),
        },
      ];
      const fields = data.GetFieldsforTeam;
      fields.forEach(field => {
        // skip fields that are already in columnDefs
        let already_present = ["opp_assigned_osa", "fub_person_id", "fub_deal_id", "opp_last_name", "opp_stage", 
                                "opp_type", "opp_appt_date", "opp_appt_met_date", "opp_price", "sisu_client_id",
                                "opp_agreement_expiration_date", "opp_commission_percent", "opp_postal_code",
                                "opp_state", "opp_forecasted_close_date", "opp_under_contract_date", "opp_settlement_date"];
        let opportunity_field_id = field.opportunity_field_id;
          
        if (field.field_type == 'default'){
          opportunity_field_id = opportunity_field_id.split('_').slice(1).join('_');
        }
        if (already_present.includes(opportunity_field_id)) {
          return;
        } 
        columnDefs.push({
          headerName: field.field_name,
          field: opportunity_field_id,
          filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
        });
      });
      setColumnDefs(columnDefs);
      setIsTableDataProcessing(false); // Set loading to false once column data is fetched
    }
  }, [data])

  const filteredOpps = useMemo(() => {
    let opps = data?.Opps;
    let totalPrice = 0;
    let totalCgi = 0;
    let count = 0;
    if (opps) {
      opps.forEach(opp => {
        count += 1;
        if (typeof opp.opp_price === 'number' && !isNaN(opp.opp_price)) {
          totalPrice = parseFloat((opp.opp_price + totalPrice).toFixed(2));
          if (typeof opp.opp_commission_percent === 'number' && !isNaN(opp.opp_commission_percent)) {
            const totalPriceWithCommission = opp.opp_price * (opp.opp_commission_percent);
            totalCgi = parseFloat((totalPriceWithCommission + totalCgi).toFixed(2));
          }
        }
      });
    }
    setdashboardcounts({
      oppcount: count,
      totalOppPrice: totalPrice,
      totalCgi: totalCgi,
    });
    return opps;
  }, [data]);

  function currencyConvert(totalOppPrice) {
    if (totalOppPrice >= 1000000000) {
      return (totalOppPrice / 1000000000).toFixed(2) + ' B';
    } else if (totalOppPrice >= 1000000) {
      return (totalOppPrice / 1000000).toFixed(2) + ' M';
    } else {
      return totalOppPrice?.toString();
    }
  }

  // const handleRefetchClick = useCallback(() => {
  //   // refetch();
  //   // setFilter(true);
  //   // setShowFilter(false);
  // }, [refetch]);

  const resetFilters = () => {
    if (gridRef.current) {
      gridRef.current.api.setFilterModel(null);
    }
  };


  useEffect(() => {
    const preparedData = Array.isArray(filteredOpps) ? filteredOpps.map(opp => ({
      ...opp,
      ...opp.opp_custom_fields
    })) : [];
    setRowData(preparedData);
  }, [filteredOpps]);

  const isExternalFilterPresent = useCallback(() => {
    // if selectedOptions.length > 0, then filter is present
    return selectedOptionsref.current.length > 0 || searchTermref.current.length > 0;
  }, [selectedOptionsref.current, searchTermref.current]);

  const doesExternalFilterPass = useCallback((node) => {
    if (node.data) {
      let opp_type_options_length = selectedOptionsref.current.length;
      let search_term_length = searchTermref.current.length;
      if (opp_type_options_length > 0 && search_term_length > 0) {
        return selectedOptionsref.current.includes(node.data.opp_type) && node.data.toString().toLowerCase().includes(searchTermref.current?.toLowerCase())
      } else if (opp_type_options_length > 0) {
        return selectedOptionsref.current.includes(node.data.opp_type)
      } else if (search_term_length > 0) {
        console.log("node", node.data, searchTermref.current)
        return node.data.toString().toLowerCase().includes(searchTermref.current?.toLowerCase())
      }
      // selectedOptionsref.current.includes(node.data.opp_type) && node.data.toString().toLowerCase().includes(searchTermref.current?.toLowerCase())
    }
    return true;
  }, [selectedOptionsref.current, searchTermref.current]);

  // const handle_opp_type_options_change = (selected) => {
  //   // setSelectedOptions(selected ? selected.map(option => option.value) : []);
  //   let newvalues =  selected ? selected.map(option => option.value) : [];
  //   if (newvalues !== selectedOptionsref.current) {
  //       selectedOptionsref.current = newvalues;
  //       gridRef.current?.api.onFilterChanged();
  //       console.log("On Change Complete", selected)
  //   }
  // };
  const handle_search_term_change = (e) => {
    let new_search_term = e.target.value;
    if (new_search_term !== searchTermref.current) {
    searchTermref.current = e.target.value
    gridRef.current?.api.onFilterChanged();
    }
  };

  const handle_opp_type_options_change = useCallback((selected) => {
    console.log("On Change", selected)
    // setSelectedOptions(selected ? selected.map(option => option.value) : []);
    let newvalues =  selected ? selected.map(option => option.value) : [];
    if (newvalues !== selectedOptionsref.current) {
        selectedOptionsref.current = newvalues;
        gridRef.current?.api.onFilterChanged();
        console.log("On Change Complete", selected)
    }
  }
  , [selectedOptionsref.current]);

  return (
    <>
      {/* <OppsNavBar /> */}
      <div>
        <section className="container-lg container-fluid pt-5">

          <section className="d-flex flex-column py-4 gap-3">
            <h5 className="m-0 fs-5 fw-bold">Opportunity list</h5>
            <p className="text-secondary">View and edit all your opportunity list and customize your data.</p>

            <div className="d-md-flex align-items-center">

              <div className="col-md-5 col-lg-5 col-xxl-4">
                <label className="small mb-2">Search</label>
                <input
                  className="form-control input-bn search-fgy mb-3"
                  placeholder="search opportunities"
                  value={searchTermref.current}
                  onChange={handle_search_term_change}
                />
              </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 px-4">
                    <label className="small mb-2">Opportunity Type</label>
                    <div className="input-group mb-3 w-100">
                    <Select
                      value={selectedOptionsref.current.map(option => ({
                        value: option,
                        label: option,
                      }))}
                      onChange={handle_opp_type_options_change}
                      isMulti={true}
                      options={dataTypes?.opportunityTypeByTeam.filter(type => type.opp_type !== "").map((type) => ({
                        value: type.opp_type,
                        label: type.opp_type
                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select opportunity type"
                    />
                    </div>
                  </div>

            </div>
            <div className="position-relative">
            <div className="row row-cols-3 py-3">

              <div>
                <div className="bg-white border rounded-3 py-3 px-4 text-center">

                  <span className="text-primary fw-bold">Counts</span>
                  <h2>{dashboardcounts.oppcount}</h2>
                  <span className="small text-secondary">{dashboardcounts.oppcount} after split Counts</span>

                </div>
              </div>

              <div>
                <div className="bg-white border rounded-3 py-3 px-4 text-center">

                  <span className="text-primary fw-bold">Total Volume</span>
                  <h2>$ {currencyConvert(dashboardcounts.totalOppPrice)}</h2>
                  <span className="small text-secondary">{dashboardcounts.totalOppPrice?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                </div>
              </div>

              <div>
                <div className="bg-white border rounded-3 py-3 px-4 text-center">

                  <span className="text-primary fw-bold">Total GCI</span>
                  <h2>$ {currencyConvert(dashboardcounts.totalCgi)}</h2>
                  <span className="small text-secondary">{dashboardcounts.totalCgi?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                </div>
              </div>
            </div>
              {loading && (
                <div className="loading-overlay">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>

            <div className="w-100">
              <section className="w-100 mb-5 px-0">
                <button onClick={resetFilters}>Reset Filters</button>
                <button onClick={exportToCsv}>Export to CSV</button>
                <div className="ag-theme-quartz" style={{ height: 700 }}>
                {isTableDataProcessing? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%' }}>
                    <Spin size="large" />
                  </div>
                  ) : (
                  <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    modules={[GridChartsModule]}
                    frameworkComponents={{
                      agGroupCellRenderer: CustomGroupCellRenderer,
                    }}
                    sideBar={sideBar}
                    enableRangeSelection={true}
                    enableCharts={true}
                    headerHeight={40}
                    rowHeight={30}
                    rowSelection={"multiple"}
                    rowGroupPanelShow={"always"}
                    pagination={true}
                    paginationPageSizeSelector={[50, 100, 200]}
                    paginationPageSize={50}
                    tooltipShowDelay={500}
                    tooltipHideDelay={500}
                    pivotMode={false}
                    pivotPanelShow={"always"}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                    onFilterChanged={onFilterChanged}
                  />
              )}
                </div>
              </section>
            </div>

          </section>
        </section>
      </div>

    </>
  );
}
